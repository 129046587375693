<template>
  <div class="topo-preview-component" v-loading="graphLoading">
    <div class="header-line">
      <div class="tabs-part">
        <span
          :class="[
            'single-tab',
            item.value === activeTab && 'single-tab-active',
          ]"
          v-for="(item, index) in typeList"
          :key="index"
          @click="tabChange(item.value)"
        >
          {{ item.label }}
        </span>
      </div>
      <div class="button-part">
        <el-button type="primary" icon="el-icon-upload" @click="exportHandler"
          >导出</el-button
        >
      </div>
    </div>
    <div class="filter-line">
      <el-date-picker
        v-show="activeTab === 'statistics'"
        v-model="dateRange"
        :clearable="false"
        @change="dateTimeChange"
        style="width: 400px"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
      <el-date-picker
        v-show="activeTab === 'realTime'"
        @change="dateTimeChange"
        value-format="timestamp"
        v-model="dateTime"
        type="datetime"
        placeholder="选择日期时间"
      >
      </el-date-picker>
      <!-- <el-input
        style="width: 200px; margin-left: 30px"
        placeholder="请输入内容"
        prefix-icon="el-icon-search"
        v-model="searchKey"
      >
      </el-input> -->
      <el-select
        style="width: 200px; margin-left: 30px"
        clearable
        v-model="searchKey"
        filterable
        placeholder="请选择"
        @change="searchKeyChange"
      >
        <el-option
          v-for="item in allNodeDatas"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="graph-container" id="graphContainer"></div>
    <TopoDownloadDialog
      :show="downloadDialogShow"
      @close="downloadDialogShow = false"
    />
  </div>
</template>

<script>
import {
  findTopoDetail,
  findDeviceDataInfoBatch,
} from "@/api/ruge/topo/topo.js";
import { dateFormat } from "@/filters/index";
import TopoDownloadDialog from "../components/topoDownloadDialog.vue";
const remarkIcon = require("@/assets/images/topology/topo_remark_icon.png");

export default {
  name: "topoPreviewComponent",
  components: {
    TopoDownloadDialog,
  },
  data() {
    return {
      downloadDialogShow: false,
      jsMindIns: null,
      topoId: "",
      graphLoading: false,
      activeTab: "realTime",
      searchKey: "",
      typeList: [
        {
          label: "实时数据",
          value: "realTime",
        },
        {
          label: "用量统计",
          value: "statistics",
        },
      ],
      dateTime: "",
      dateRange: [],
      pkdnList: [],
      pkdnMaps: {},
      nodeValueMap: {},
      nodeUnitMap: {},
      statisticsDatas: [],
      allNodeDatas: [],
    };
  },
  watch: {
    activeTab() {
      this.typeToggle();
    },
  },
  mounted() {
    this.initGraph();
    this.displayDatas();
  },
  methods: {
    searchKeyChange(nodeId) {
      this.jsMindIns.select_node(nodeId);
      try {
        const targetDom = $(`#${nodeId}`)[0] || $(".topo-default-root")[0];
        targetDom.scrollIntoView({ block: "center" });
      } catch (error) {
        console.log("scrollIntoView失败：", error);
      }
    },
    // png, jpeg, pdf
    exportHandler() {
      this.downloadDialogShow = true;
    },
    bindEvent() {
      $(".image-span").hover(
        //为li绑定了鼠标进入和鼠标移开的两个参数
        function () {
          console.log("enter");
          $(this).find(".remark-span").css("display", "inline-block");
        },
        function () {
          console.log("leave");
          $(this).find(".remark-span").css("display", "none");
        }
      );
      $(".topic-span").on("click", (event) => {
        const currentDom = $(event.target);
        const productKey = currentDom.data("product-key").split("-")[0];
        const deviceName = currentDom.data("device-name");
        this.jumpToDeviceShadow(productKey, deviceName);
      });
    },
    jumpToDeviceShadow(productKey, deviceName) {
      const { origin, pathname } = window.location;
      const targetUrl = `${origin}${pathname}#/iot/device/detail?productKey=${productKey}&deviceName=${deviceName}&activeTabName=shadow`;
      window.open(targetUrl, "_blank");
    },
    updateNode(ids, value, value2, hideFlag) {
      try {
        ids.forEach((item) => {
          $("#" + item)
            .find(`.value-span`)
            .text(value);
        });
        ids.forEach((item) => {
          $("#" + item)
            .find(`.time-span`)
            .text(value2);
        });
        ids.forEach((item) => {
          if (hideFlag) {
            $("#" + item)
              .find(`.time-line`)
              .hide();
          } else {
            $("#" + item)
              .find(`.time-line`)
              .show();
          }
        });
      } catch (error) {
        console.log("更新节点失败:", error);
      }
    },
    initGraph() {
      const options = {
        container: "graphContainer",
        theme: "default",
        editable: false,
        view: {
          draggable: true,
          hmargin: 300,
          vmargin: 300,
        },
      };
      this.jsMindIns = new jsMind(options);
      this.jsMindIns.show();
    },
    getDetailHandler(topoId) {
      return findTopoDetail(topoId).then((res) => {
        return res;
      });
    },
    async displayDatas() {
      this.graphLoading = true;
      const { topoId, publish } = this.$route.query;
      if (topoId) {
        try {
          // 渲染图形
          this.topoId = topoId;
          const detail = await this.getDetailHandler(topoId);
          const { draftTopoJson, publishTopoJson } = detail;
          const parseJson = publish
            ? JSON.parse(publishTopoJson)
            : JSON.parse(draftTopoJson);
          // 存储[pkdn]
          this.pkdnList = this.storeDeviceKeys(parseJson);
          const graphDatas = this.buildDetailLoop(parseJson.data);
          parseJson.data = graphDatas;
          this.jsMindIns.show(parseJson);
          this.typeToggle();
          // 获取设备数据
          this.getDeviceDatas();
        } catch (error) {
          this.$message.warning("初始化图形失败！");
          this.graphLoading = false;
          console.log("error", error);
        } finally {
          this.bindEvent();
          this.setAllNodeLabel();
        }
      }
    },
    getDeviceDatas() {
      let params = {
        deviceParam: this.pkdnList
          .map((item) => `${item.productKey.split("-")[0]}:${item.deviceName}`)
          .join(","),
      };
      if (this.activeTab === "realTime") {
        params.stateTime = this.dateTime || new Date().getTime();
      } else {
        this.resetWarningNode();
        console.log("this.dateRange", this.dateRange);
        const [startTime, endTime] = this.dateRange || [];
        if (!startTime || !endTime) {
          this.graphLoading = false;
          this.$message.warning("请选择时间范围！");
          return;
        }
        params.stateTime = new Date(startTime).getTime();
        params.endTime = new Date(endTime).getTime();
      }
      this.statisticsDatas = [];
      findDeviceDataInfoBatch(params)
        .then((res) => {
          console.log("返回结果：", res);
          if (this.activeTab === "realTime") {
            this.dealRealTimeDatas(res);
          } else {
            this.updateNodeValueMap(res);
            this.dealStatisticsDatas(res);
          }
        })
        .finally(() => {
          this.graphLoading = false;
        });
    },
    tabChange(key) {
      this.graphLoading = true;
      this.activeTab = key;
      this.typeToggle();
      this.getDeviceDatas();
    },
    dealRealTimeDatas(res) {
      res.forEach((item) => {
        const { deviceName, productKey, moduleS } = item;
        if (!deviceName || !productKey) return;
        const currentIds = this.getNodeIdByPkdn(productKey, deviceName);
        const { value, unit, updateTime } = moduleS[0].properties[0];
        const value1 = this.currentTotalRender(value, updateTime, unit);
        const value2 = dateFormat(updateTime, "YYYY-MM-DD HH:mm");
        this.updateNode(currentIds, value1, value2);
      });
    },
    currentTotalRender(value, updateTime, unit) {
      if (updateTime) {
        if (value && value !== "null") {
          return `${value}${unit || ""}`;
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    },
    updateNodeValueMap(res) {
      this.nodeValueMap = {};
      res.forEach((item) => {
        const { deviceName, productKey, moduleS } = item;
        if (!deviceName || !productKey) return;
        const currentIds = this.getNodeIdByPkdn(productKey, deviceName);
        const { value, unit } = moduleS[0].properties[0];
        currentIds.forEach((item) => {
          this.nodeValueMap[item] = this.filterBadValue(value);
          this.nodeUnitMap[item] = unit;
        });
      });
    },
    filterBadValue(value) {
      try {
        if (value === "-" || value === "null") {
          return "-";
        }
        if (typeof value === "string") {
          return Number(value);
        }
      } catch (error) {
        console.log("error:", error);
        return 0;
      }
    },
    dealStatisticsDatas(res) {
      Object.keys(this.pkdnMap).forEach((singleNode) => {
        const nodeItem = this.pkdnMap[singleNode];
        // 如果这个节点没有pk或者dn，则去获取他的子节点更新和
        if (!nodeItem.deviceName || !nodeItem.productKey) {
          this.calcChildrenTotal([singleNode], "-");
          return;
        }
        // 如果有pkdn，遍历接口返回结果
        res.forEach((item) => {
          const { deviceName, productKey, moduleS } = item;
          if (
            nodeItem.deviceName === deviceName &&
            nodeItem.productKey === productKey
          ) {
            const currentIds = this.getNodeIdByPkdn(productKey, deviceName);
            const { value, unit } = moduleS[0].properties[0];
            this.calcChildrenTotal(
              currentIds,
              this.filterBadValue(value),
              unit
            );
            // this.updateNode(currentIds, value1, value2);
          }
        });
      });
    },
    setWarningNode(currentId) {
      $("#" + currentId).css("border", "4px solid #ff0000");
    },
    resetWarningNode() {
      $(".preview-node").css("border", "1px solid #ccc");
    },
    calcChildrenTotal(parentIds, value1, unit) {
      parentIds.forEach((singleItem) => {
        const currentNode = this.jsMindIns.get_node(singleItem);
        const allChildrens = currentNode.children;
        if (allChildrens && allChildrens.length) {
          let counter;
          let countUnit = "";
          allChildrens.forEach((item) => {
            const { id } = item;
            if (
              this.nodeValueMap[id] !== undefined &&
              this.nodeValueMap[id] !== "-"
            ) {
              if (!counter) counter = 0;
              counter += this.nodeValueMap[id] || 0;
            }
            if (!countUnit) {
              countUnit = this.nodeUnitMap[id] || "";
            }
          });
          counter = counter && counter.toFixed(1);
          // 如果父节点是-
          if (value1 === "-") {
            this.updateNode(
              [singleItem],
              value1,
              counter ? `${counter || "-"}${countUnit}` : "-"
            );
            return;
          }
          // 如果父节点值是0
          if (value1 == 0) {
            if (counter === undefined) {
              // 有子节点但是子节点没绑定设备的情况
              this.updateNode([singleItem], value1, `-`);
              return;
            }
            this.updateNode([singleItem], value1, `${counter}(--%)`);
            return;
          }
          const flag = counter > value1 ? "↑" : "↓";
          const diff = (
            (Math.abs(counter - value1) / Math.abs(value1)) *
            100
          ).toFixed(1);
          // 如果超出1%的范围
          if (diff > 1 && value1 !== 0) {
            this.setWarningNode(singleItem);
            this.updateNode(
              [singleItem],
              `${value1}${unit || ""}`,
              `${counter}${countUnit} (${flag} ${diff}%)`
            );
          } else {
            this.updateNode(
              [singleItem],
              value1 === "-" ? value1 : `${value1}${unit || ""}`,
              counter ? `${counter}${countUnit}` : "-"
            );
          }
        } else {
          this.updateNode(
            [singleItem],
            value1 === "-" ? value1 : `${value1}${unit || ""}`,
            "-",
            true
          );
        }
      });
    },
    dateTimeChange() {
      this.graphLoading = true;
      console.log("this.dateRange", this.dateRange);
      if (!this.dateRange && this.activeTab === "statistics") {
        this.resetStatistics();
        this.$message.warning("请选择时间范围！");
        this.graphLoading = false;
      }
      this.getDeviceDatas();
    },
    resetStatistics() {
      this.resetWarningNode();
      $(".value-span").text("-");
      $(".time-span").text("-");
    },
    getNodeIdByPkdn(pk, dn) {
      let resNode = [];
      for (let i = 0; i < this.pkdnList.length; i++) {
        const { productKey, deviceName, nodeId } = this.pkdnList[i];
        if (productKey.split("-")[0] === pk && deviceName === dn) {
          resNode.push(nodeId);
        }
      }
      return resNode;
    },
    setAllNodeLabel() {
      const rootLabel = $(".topo-default-root").text();
      this.allNodeDatas.forEach((item) => {
        const label = $("#" + item.value)
          .find(".topic-span")
          .text();
        item.label = label.trim() || rootLabel.trim();
      });
    },
    storeDeviceKeys(parseJson) {
      let pkdnList = [];
      let pkdnMap = {};
      let allNodeDatas = [];
      function getPkDn(data) {
        const { productKey, deviceName } = data.data || {};
        allNodeDatas.push({
          value: data.id,
          label: "",
        });
        pkdnMap[data.id] = {
          productKey: productKey.split("-")[0],
          deviceName,
        };
        if (productKey && deviceName) {
          pkdnList.push({
            productKey: productKey.split("-")[0],
            deviceName,
            nodeId: data.id,
          });
        }
        if (data.children && data.children.length) {
          data.children.forEach((item) => {
            getPkDn(item);
          });
        }
      }
      getPkDn(parseJson.data);
      this.pkdnMap = pkdnMap;
      this.allNodeDatas = allNodeDatas;
      return pkdnList;
    },
    buildDetailLoop(datas) {
      // 根节点是Object，children是Array
      if (datas instanceof Array) {
        datas.forEach((item) => {
          this.childrenBuild(item);
          if (item.children && item.children.length) {
            this.buildDetailLoop(item.children);
          }
        });
      } else {
        if (datas.id === "root") {
          const { productKey, deviceName } = datas.data || {};
          if (!productKey || !deviceName) {
            this.rootBuild(datas);
          } else {
            this.childrenBuild(datas);
          }
          datas.children && this.buildDetailLoop(datas.children);
        }
      }
      return datas;
    },
    childrenBuild(datas) {
      console.log("datas", datas);
      const { productKey, deviceName, value, time, remark } = datas.data;
      const fontSize = datas["font-size"] ? `${datas["font-size"]}px` : "14px";
      const fontColor = datas["foreground-color"] || "#000000";
      const fontWeight = datas["font-weight"] || "normal";
      const fontStyle = datas["font-style"] || "normal";
      const currentValue1 = "-";
      const currentValue2 = "-";
      datas.topic = `
        <div id="${
          datas.id
        }" class='preview-node' style='min-height: 60px; min-width: 230px;'>
          <div class="title-line">
            <span class="topic-span" data-product-key="${productKey}" data-device-name="${deviceName}" style="cursor: pointer;font-size: ${fontSize};color: ${fontColor};font-weight: ${fontWeight}; font-style: ${fontStyle}">
              ${datas.topic}
            </span>
            <span class="image-span" style="display: ${
              remark ? "inline-flex" : "none"
            }">
              <img class="remark-img" src="${remark && remarkIcon}" alt="">
              <div class="remark-span" >${remark}</div>
            </span>
          </div>
          <div class="value-line">
            <span class="realtime-span">
              当前值：
              <span class="value-span">${currentValue1}</span>              
              </span>
              <span class="statistics-span">
                用量值：
                <span class="value-span">${currentValue1}</span>
            </span>
          </div>
          <div class="time-line">
            <span class="realtime-span">
              上报时间：
              <span class="time-span">
                ${currentValue2}
              </span>
            </span>
            <span class="statistics-span">
              和：
              <span class="time-span">
                ${currentValue2}
              </span>
            </span>
          </div>
        </div>
      `;
    },
    async typeToggle() {
      await this.$nextTick();
      this.resetWarningNode();
      $(".value-span").text("-");
      $(".time-span").text("-");
      if (this.activeTab === "realTime") {
        $(".realtime-span").show();
        $(".statistics-span").hide();
      } else {
        $(".realtime-span").hide();
        $(".statistics-span").show();
      }
    },
    rootBuild(datas) {
      // console.log("datas", datas);
      const { productKey, deviceName, remark } = datas.data || {};
      // 如果根节点没有配置pk/dn，那渲染成默认跟节点
      if (!productKey || !deviceName) {
        datas.topic = `
        <div class="topo-root-container">
          <span class="topo-default-root">${datas.topic}</span>
          <span class="image-span" style="display: ${
            remark ? "inline-flex" : "none"
          }">
              <img class="remark-img" src="${remark && remarkIcon}" alt="">
              <div class="remark-span" >${remark}</div>
            </span>
        </div>
        `;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.topo-preview-component {
  // 解决用scrollIntoView父容器会滑动的问题
  position: fixed;
  background: #ffffff;
  width: 100%;
  height: 100vh;
  .header-line {
    height: 50px;
    line-height: 50px;
    width: 100%;
    border-bottom: 1px solid #e3e8ee;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    .tabs-part {
      .single-tab {
        font-size: 18px;
        font-weight: bold;
        color: #8190ac;
        cursor: pointer;
      }
      .single-tab-active {
        color: #152c5b;
      }
      .single-tab + .single-tab {
        margin-left: 30px;
      }
    }
  }
  .filter-line {
    height: 75px;
    display: flex;
    align-items: center;
    padding: 0 30px;
  }
  .graph-container {
    height: calc(100vh - 125px);
    width: 100%;
  }
}
</style>